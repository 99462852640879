.footer {
  // position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  background: #E6E6E6;
  // position: absolute;
  // bottom: 0;
  > p {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #808080;
  }
}