header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  background: #FFFFFF;
  border-bottom: 1px solid #E6E6E6;
  backdrop-filter: blur(10px);
  width: 100%;
  height: 72px;
  > a {
    > .left_box {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-flow: row nowrap;
      width: 274px;
      height: 32px;
      margin-left: 104px;
      > .logo {
        width: 100%;
        height: 100%;
        background: url(../../assets/images/admin_logo.png) no-repeat 50% 50%;
        background-size: contain;
      }
    }
  }
  > .right_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row nowrap;
    > .lang {
      cursor: pointer;
      margin-left: 26px;
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      color: #717D96;
      &:first-child {
        margin: 0;
      }
      &.on {
        text-decoration-line: underline;
        color: #2D3648;
      }
    }
    > .login {
      cursor: pointer;
      margin: 20px 104px 20px 54px;
      background: #2D3648;
      border-radius: 22px;
      padding: 10px 24px 9px;
      > p {
        font-weight: 400;
        font-size: 13px;
        line-height: 100%;
        color: #FFFFFF;
      }
    }
  }
}