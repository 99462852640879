.container {
  >&.board_write {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: column nowrap;
    height: 100%;

    >.content_frame {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-flow: column nowrap;
      height: 100%;

      >.board_title {
        margin-top: 120px;
        margin-bottom: 40px;
        width: 100%;

        >p {
          color: #2D3648;
          font-weight: 700;
          font-size: 38px;
          line-height: 140%;
        }
      }

      >.top_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-flow: row nowrap;
        width: 100%;
        margin-bottom: 20px;
        >.write_category {
          -o-appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;

          box-sizing: border-box;
          padding: 11px 12px;
          width: 250px;
          height: 40px;
          background: #FFFFFF;
          border: 1px solid #E6E6E6;
          border-radius: 5px;
          font-weight: 400;
          font-size: 15px;
          line-height: 100%;
          color: #2D3648;
          margin-right: 10px;
          background: url(../../assets/images/board_select_box_icon.png) calc(100% - 12px) center no-repeat;
          outline: 0 none;
          > option {
            box-sizing: border-box;
            padding: 11px 12px;
            font-weight: 400;
            font-size: 15px;
            line-height: 100%;
            color: #2D3648;
          }
        }

        >.write_title {
          box-sizing: border-box;
          padding: 12px 15px;
          width: 870px;
          height: 40px;
          background: #FFFFFF;
          border: 1px solid #E6E6E6;
          border-radius: 5px;
          font-weight: 400;
          font-size: 15px;
          line-height: 100%;
          &::placeholder {
            color: #A0ABC0;
          }
        }
      }
      > .text-editor {
        width: 100%;
        // height: 650px;
        margin-bottom: 40px;
        > .thumbnail,
        > .attachment {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-flow: row nowrap;
          margin-top: 40px;
          background: #F8F9FA;
          border-radius: 5px;
          padding: 10px 15px;
          width: 100%;
          height: auto;
          > .thumbnail_left,
          > .attachment_left {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-flow: row nowrap;
            > .thumbnail_title,
            > .attachment_title {
              font-weight: 500;
              font-size: 16px;
              line-height: 28px;
              color: #152536;
              // margin-left: 15px;
              // height: 28px;
              margin: 5px 20px 5px 0;
            }
            > .preview_wrapper,
            > .attachment_wrapper {
              > .img_preview {
                width: 400px;
                height: auto;
                object-fit: contain;
              }
              > p {
                font-weight: 300;
                font-size: 15px;
                line-height: 28px;
                color: #717D96;
                margin: 5px 0;
                &.error {
                  font-weight: 300;
                  font-size: 15px;
                  line-height: 28px;
                  color: #FF5252;
                }
              }
            }
          }
          > .thumbnail_file_box,
          > .attachment_file_box {
            justify-self: flex-end;
            > .delete {
              cursor: pointer;
              box-sizing: border-box;
              padding: 10px 16px;
              background: #FFFFFF;
              border: 1px solid #CBD2E0;
              border-radius: 4px;
              margin-right: 10px;
            }
            > .thumbnail_file,
            > .attachment_file {
              box-sizing: border-box;
              cursor: pointer;
              padding: 10px 16px;
              width: 80px;
              height: 38px;
              background: #E2E6EA;
              border: 1px solid #CBD2E0;
              border-radius: 4px;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              text-align: center;
              color: #717D96;
            }

            > input[type="file"] {
              display: none;
            }
          }
        }
        > .button_wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-flow: row nowrap;
          margin-top: 40px;
          margin-bottom: 80px;
          > .cancel {
            cursor: pointer;
            padding: 18px 30px;
            width: 138px;
            height: 52px;
            border: 1px solid #2D3648;
            border-radius: 26px;
            font-weight: 400;
            font-size: 16px;
            line-height: 100%;
            color: #2D3648;
            text-align: center;
            margin-right: 10px;
          }
          > .regist {
            cursor: pointer;
            padding: 18px 30px;
            width: 138px;
            height: 52px;
            background: #2D3648;
            border-radius: 26px;
            font-weight: 400;
            font-size: 16px;
            line-height: 100%;
            color: #FFFFFF;
            text-align: center;
          }
        }
      }
    }
  }
}

// .se-toolbar-sticky {
//   width: 100% !important;
// }