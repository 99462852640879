.container {
  > &.board_manager {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: column nowrap;
    height: 100%;
    // new body scss
    > .content_frame {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-flow: column nowrap;
      // padding: 0 396px;
      // width: 100%;
      height: 100%;
      > .board_title {
        margin-top: 120px;
        margin-bottom: 80px;
        width: 100%;
        > p {
          color:#2D3648;
          font-weight: 700;
          font-size: 38px;
          line-height: 140%;
        }
      }

      > .board_menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-flow: row nowrap;
        width: 100%;
        > p {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-flow: row nowrap;
          position: relative;
          flex: 1;
          width: 376px;
          height: 46px;
          top: 16px;
          font-size: 14px;
          line-height: 100%;
          font-weight: 600;
          color: #717D96;
          text-align: center;
          cursor: pointer;
          &.on,
          &:hover {
            color: #2D3648;
            &::after {
              position: absolute;
              left: 0;
              bottom: 0;
              content: "";
              width: 100%;
              height: 2px;
              background: #2D3648;
            }
          }
        }
      }
      
      > .board_table {
        margin-top: 40px;
        width: 100%;
        border-top: 1px solid #717D96;
        border-bottom: 1px solid #717D96;
        > thead {
          > tr {
            > th {
              font-weight: 500;
              font-size: 15px;
              line-height: 100%;
              text-align: center;
              color: #2D3648;
              background: #F7F9FC;
              padding: 20px 0;
              &:first-child {
                margin-left: 33px;
              }
              &:last-child {
                margin-right: 59px;
              }
            }
          }
        }
        > tbody {
          > tr {
            cursor: pointer;
            border-top: 1px solid #E6E6E6;
            height: 63px;
            > td {
              padding: 20px 0px;
              &.center {
                > p {
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 140%;
                  color: #2D3648;
                  text-align: center;
                }
              }
              &:first-child {
                text-align: center;
              }
              &:last-child {
                text-align: right;
              }
              >.icon {
                border-radius: 20px;
                padding: 2px 12px;
                font-weight: 700;
                font-size: 12px;
                line-height: 160%;
                color: #FFFFFF;
                display: inline-block;
                > &.news {
                  background: #34AFF7;
                }
                > &.notice {
                  background: #7860EA;
                }
              }
              &.board_item_title {
                > p {
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 140%;
                  color: #2D3648;
                }
              }
              &.board_item_date {
                > p {
                  margin-right: 20px;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 100%;
                  color: #717D96;
                }
              }
            }
          }
        }
      }

      > .table_footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-flow: row nowrap;
        width: 100%;
        margin-top: 32px;
        &.mb-70 {
          margin-bottom: 70px;
        }
        > .regist_button {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-flow: row nowrap;
          padding: 18px 30px;
          border: 1px solid #2D3648;
          border-radius: 26px;
          margin-bottom: 80px;
          cursor: pointer;
          > p {
            font-weight: 400;
            font-size: 16px;
            line-height: 100%;
            color: #2D3648;
          }
          > .board_regist_icon {
            width: 14px;
            height: 14px;
            margin-left: 5px;
            background: url(../../assets/images/board_regist_button.png) no-repeat 50% 50%;
            background-size: contain;
          }
          &:hover {
            background: #2D3648;
            > p {
              color: #FFFFFF;
            }
            > .board_regist_icon {
              background: url(../../assets/images/board_regist_button_hover.png) no-repeat 50% 50%;
            }
          }
        }
      }

      > .board_pagination {
        width: 100%;
        height: 64px;
        margin-top: 62px;
        padding-bottom: 70px;
        // margin-bottom: calc(100vh - 805px - 73px - 100px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: row nowrap;
        > li {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          width: 62px;
          height: 21px;
          text-align: center;
          &.previous {
            > a {
              justify-self: flex-end;
              width: 24px;
              height: 24px;
              // background: #2D3648;
              background: url(../../assets/images/board_previous.png) no-repeat 50% 50%;
              background-size: contain;
              &:hover, &:active {
                background: url(../../assets/images/board_next.png) no-repeat 50% 50%;
                transform: rotate(180deg);
              }
            }
            &.disabled {
              > a {
                justify-self: flex-end;
                width: 24px;
                height: 24px;
                // background: #2D3648;
                background: url(../../assets/images/board_previous.png) no-repeat 50% 50%;
                background-size: contain;
                &:hover, &:active {
                  background: url(../../assets/images/board_previous.png) no-repeat 50% 50%;
                  transform: rotate(0deg);
                }
              }
            }
          }
          &.next {
            > a {
              justify-self: flex-end;
              width: 24px;
              height: 24px;
              // background: #2D3648;
              background: url(../../assets/images/board_previous.png) no-repeat 50% 50%;
              transform: rotate(180deg);
              background-size: contain;
              &:hover, &:active {
                background: url(../../assets/images/board_next.png) no-repeat 50% 50%;
                transform: rotate(0deg);
              }
            }
            &.disabled {
              a {
                justify-self: flex-end;
                width: 24px;
                height: 24px;
                // background: #2D3648;
                background: url(../../assets/images/board_previous.png) no-repeat 50% 50%;
                transform: rotate(180deg);
                background-size: contain;
                &:hover, &:active {
                  background: url(../../assets/images/board_previous.png) no-repeat 50% 50%;
                  transform: rotate(180deg);
                }
              }
            }
          }

          &.break {
            > a {
              font-weight: 400;
              font-size: 18px;
              line-height: 21px;
              color: #717D96;
            }
          }
          &.selected {
            > a {
              color: #34AFF7;
              text-decoration-line: underline;
            }
          }
          &:hover {
            text-decoration-line: underline;
          }
          > a {
            text-align: center;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: #717D96;
          }
        }
      }
    }
  }
}