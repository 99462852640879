// app.scss
.app {
  display: flex;
  // align-items: center;
  // justify-content: space-between;
  flex-flow: column nowrap;
  width: 100%;
  height: 100vh;
  > header {
    box-sizing: border-box;
  }
  > .content {
    width: 100%;
    height: calc(100% - 100px);
    min-height: calc(100vh - 100px);
    overflow-y: scroll;
  }
  > .footer {
    box-sizing: border-box;
  }
}

.se-image-container {
  > figure {
    >img {
      max-width: 1124px;
    }
  }
}

.board_detail_text {
  div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    font-size: inherit;
    font: inherit;
    vertical-align: inherit;
    font-family: inherit;
    color: inherit;
    font-size: inherit;
    -webkit-user-select: inherit;
    user-select: inherit;
    -webkit-font-smoothing: inherit;
    letter-spacing: inherit;
  }
  ol, ul, li {
    list-style: unset;
  }
  ol, ul {
    list-style-position: outside;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }
  ol {
    list-style-type: decimal;
  }
  ul {
    list-style-type: disc;
  }
  li {
    display: list-item;
    text-align: -webkit-match-parent;
    margin-bottom: 5px;
    &::marker {
      unicode-bidi: isolate;
      font-variant-numeric: tabular-nums;
      text-transform: none;
      text-indent: 0px !important;
      text-align: start !important;
      text-align-last: start !important;
    }
  }

  pre {
    display: block;
    padding: 8px;
    margin: 0 0 10px;
    font-family: monospace;
    color: #666;
    line-height: 1.45;
    background-color: #f9f9f9;
    border: 1px solid #e1e1e1;
    border-radius: 2px;
    white-space: pre-wrap!important;
    word-wrap: break-word;
    overflow: visible;
  }
  
  blockquote {
    display: block;
    font-family: inherit;
    font-size: inherit;
    color: #999;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding: 0 5px 0 20px;
    border: solid #b1b1b1;
    border-width: 0 0 0 5px;
  }
  h1, h2 {
    display: block;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: 700;
  }
  h1 {
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
  }
  h2 {
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
  }
  h3, h4 {
    display: block;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: 700;
  }
  h3 {
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
  h4 {
    font-size: 1em;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
  }
  h5, h6 {
    display: block;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: 700;
  }
  h5 {
    font-size: .83em;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
  }
  h6 {
    font-size: .67em;
    margin-block-start: 2.33em;
    margin-block-end: 2.33em;
  }

  .__se__p-spaced {
    letter-spacing: 1px;
  }
  .__se__p-bordered {
    border-top: 1px solid #b1b1b1;
    border-bottom: 1px solid #b1b1b1;
    padding: 4px 0;
  }
  .__se__p-neon {
    font-weight: 200;
    font-style: italic;
    background: #000;
    color: #fff;
    padding: 6px 4px;
    border: 2px solid #fff;
    border-radius: 6px;
    text-transform: uppercase;
    animation: neonFlicker 1.5s infinite alternate;
}
.se-video-container {
  figure {
    // height: 650px;
    padding-bottom: 80px !important;
  }
}

  p {
    display: block;
    margin: 0 0 10px;
  }
  strong {
    font-weight: 600;
  }
  em {
    font-style: italic;
  }
  span, strong, del, u {
    // display: inline-block;
  }

  hr {
    &.__se__dashed {
      border-style: dashed none none;
    }
    &.__se__solid {
      border-style: solid none none;
    }
    &.__se__dotted {
      border-style: dotted none none;
    }
  }

  table {
    display: table;
    table-layout: auto!important;
    border: 1px solid #ccc;
    width: 100%;
    max-width: 100%;
    margin: 0 0 10px;
    background-color: transparent;
    border-spacing: 0;
    border-collapse: collapse;
    thead {
      border-bottom: 2px solid #333;

    }
    tr {
      border: 1px solid #efefef;
    }
    th {
      background-color: #f3f3f3;
    }
    td, th {
      border: 1px solid #e1e1e1;
      padding: 0.4em;
      background-clip: padding-box;
    }
  }
}

.sun-editor {
  em {
    font-style: italic;
  }
  strong {
    font-weight: 600;
  }
  ol, ul, li {
    list-style: unset;
  }
}