.container {
  &.board_detail {
    display: flex;
    flex-flow: column nowrap;
    width: 1128px;
    height: 100%;
    margin: 120px 0;

    >.board_detail_header {
      // display: flex;
      // align-items: flex-start;
      // justify-content: flex-start;
      // flex-flow: column nowrap;
      width: 100%;
      border-bottom: 1px solid #E6E6E6;
      margin-top: 120px;
      margin-bottom: 40px;

      >.icon {
        border-radius: 20px;
        padding: 2px 12px;
        font-weight: 700;
        font-size: 12px;
        line-height: 160%;
        color: #FFFFFF;
        display: inline-block;
        margin-bottom: 16px;

        >&.news {
          width: 30px;
          background: #34AFF7;
        }

        >&.notice {
          width: 36px;
          background: #7860EA;
        }
      }

      >.board_detail_title {
        margin-bottom: 16px;

        >p {
          font-weight: 700;
          font-size: 38px;
          line-height: 140%;
          align-items: center;
          letter-spacing: 0.01em;
          color: #2D3648;
        }
      }

      >.board_detail_date {
        margin-bottom: 24px;

        >p {
          font-weight: 400;
          font-size: 16px;
          line-height: 100%;
          color: #717D96;
        }
      }

      >.board_detail_attachment {
        width: 100%;
        margin-bottom: 24px;

        >.title {
          font-weight: 700;
          font-size: 16px;
          line-height: 100%;
          color: #717D96;
          margin-bottom: 8px;
        }

        >.box {
          width: 100%;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;

          >.button_attachment {
            position: relative;
            padding: 9px 9px 9px 30px;
            border: 1px solid #EDF0F7;
            border-radius: 4px;
            cursor: pointer;

            >p {
              font-weight: 500;
              font-size: 14px;
              line-height: 100%;
              color: #34AFF7;
              // text-indent: 21px;
            }

            &::after {
              content: "";
              position: absolute;
              top: calc(50% - 8px);
              left: 9px;
              width: 16px;
              height: 16px;
              background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 0.666992C3.46957 0.666992 2.96086 0.877706 2.58579 1.25278C2.21071 1.62785 2 2.13656 2 2.66699V13.3337C2 13.8641 2.21071 14.3728 2.58579 14.7479C2.96086 15.1229 3.46957 15.3337 4 15.3337H12C12.5304 15.3337 13.0391 15.1229 13.4142 14.7479C13.7893 14.3728 14 13.8641 14 13.3337V6.00033C14 5.82351 13.9298 5.65394 13.8047 5.52892L9.13807 0.862274C9.07427 0.798475 8.99887 0.748923 8.91683 0.71571C8.8381 0.683836 8.75325 0.666992 8.66667 0.666992H4ZM8 2.00033H4C3.82319 2.00033 3.65362 2.07056 3.5286 2.19559C3.40357 2.32061 3.33333 2.49018 3.33333 2.66699V13.3337C3.33333 13.5105 3.40357 13.68 3.5286 13.8051C3.65362 13.9301 3.82319 14.0003 4 14.0003H12C12.1768 14.0003 12.3464 13.9301 12.4714 13.8051C12.5964 13.68 12.6667 13.5105 12.6667 13.3337V6.66701H8.66667C8.29848 6.66701 8 6.36853 8 6.00034V2.00033ZM11.7239 5.33368L9.33333 2.94315V5.33368H11.7239Z' fill='%23A0ABC0'/%3E%3C/svg%3E%0A") no-repeat 50% 50%;
              background-size: contain;
            }
          }
        }
      }
    }

    >.board_detail_body {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-flow: column nowrap;
      width: 100%;
      padding-bottom: 20px;

      >.board_detail_content {
        width: 100%;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // flex-flow: column nowrap;
        border-bottom: 1px solid #E6E6E6;
        margin-bottom: 80px;

        >.board_detail_image {
          width: 100%;
          height: 800px;
          background: url(../../assets/images/board_detail_temp_images.png) no-repeat 50% 50%;
          background-size: contain;
          margin-bottom: 40px;
        }

        >.board_detail_text {
          // font-weight: 400;
          // font-size: 16px;
          // line-height: 160%;
          // color: #717D96;
          margin-bottom: 80px;
        }
      }

      >.board_detail_func {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-flow: row nowrap;
        width: 100%;
        margin-bottom: 40px;

        >.board_detail_left {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-flow: row nowrap;
          width: 138px;
          height: 52px;
          border: 1px solid #2D3648;
          border-radius: 26px;
          padding: 18px 0;

          >p {
            font-weight: 400;
            font-size: 16px;
            line-height: 100%;
            color: #2D3648;
            text-align: center;
            margin-right: 5px;
          }

          >.icon {
            width: 14px;
            height: 14px;
            background: url(../../assets/images/board_item_list_icon.png) no-repeat 50% 50%;
            background-size: contain;
          }

          &:hover {
            background: #2D3648;
            border-radius: 26px;

            >p {
              color: #FFFFFF;
            }

            >.icon {
              background: url(../../assets/images/board_item_list_icon_hover.png) no-repeat 50% 50%;
            }
          }

        }

        >.board_detail_right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-flow: row nowrap;
          width: 100%;

          >.update {
            cursor: pointer;
            padding: 18px 30px;
            width: 138px;
            height: 52px;
            border: 1px solid #2D3648;
            border-radius: 26px;

            >p {
              font-weight: 400;
              font-size: 16px;
              line-height: 100%;
              color: #2D3648;
              text-align: center;
            }
          }

          >.delete {
            cursor: pointer;
            padding: 18px 30px;
            width: 138px;
            height: 52px;
            background: #2D3648;
            border-radius: 26px;
            margin-left: 10px;

            >p {
              font-weight: 400;
              font-size: 16px;
              line-height: 100%;
              color: #FFFFFF;
              text-align: center;
            }
          }
        }
      }
    }
  }
}



// .container {
//   &.board_detail {
//     display: flex;
//     align-items: center;
//     // justify-content: flex-start;
//     justify-content: center;
//     flex-flow: column nowrap;
//     // padding: 120px 396px;
//     width: 100%;
//     
//   }

// }