.content {
  width:100%;
  height:100%;
  position:relative;
  display: flex;
  align-items: center;
  justify-content: center;
  > .login_wrapper {
    margin-top: 360px;
    margin-bottom: calc(100vh - 724px);
    // position: absolute;
    width: 330px;
    height: 265px;
    > .logo_wrapper {
      width: 100%;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-flow: row nowrap;
      margin-bottom: 40px;
      > .login_logo {
        width: 100%;
        height: 100%;
        background: url(../../assets/images/admin_logo.png) no-repeat 50% 50%;
        background-size: contain;
      }
    }

    > .login_input {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column nowrap;
      width: 100%;
      > input {
        box-sizing: border-box;
        width: 100%;
        height: 40px;
        padding: 12px 15px;
        background: #FFFFFF;
        border: 1px solid #E6E6E6;
        border-radius: 5px;
        margin-bottom: 10px;

        font-weight: 400;
        font-size: 15px;
        line-height: 100%;
        &::placeholder {
          color: #A0ABC0;
        }
        &:last-child {
          margin-bottom: 40px;
        }
      }

    }

    > .login_button {
      box-sizing: border-box;
      width: 100%;
      height: 52px;
      text-align: center;
      padding: 18px 30px;
      background: #2D3648;
      border-radius: 5px;
      cursor: pointer;
      > p {
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        color: #FFFFFF;
      }
    }
  
  }
}
