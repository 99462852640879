.dim {
  z-index: 1000 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.7);

  >.modal {
    position: absolute;
    top: 441px;
    left: calc(50% - 180px);
    width: 311px;
    height: 198px;
    background: #FFF;
    border-radius: 30px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    padding: 20px 24px;
    // padding: 50px 75px 32px 75px;
  }
  >.alert {
    > .close_button {
      position: absolute;
      top: 16px;
      right: 24px;
      width: 12px;
      height: 12px;
      background: url(../../../assets/images/modal_close_icon.png) no-repeat 50% 50%;
      background-size: contain;
      cursor: pointer;
    }
    > .modal_inner {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 24px 0;
      > .content {
        flex-flow: column nowrap;
        > .title {
          font-weight: 700;
          font-size: 16px;
          line-height: 180%;
          color: #2D3648;
        }
        > .description {
          font-weight: 400;
          font-size: 16px;
          line-height: 180%;
          color: #717D96;
        }
      }
    }
    > .button {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      > .modal_close_button {
        cursor: pointer;
        > p {
          font-weight: 400;
          font-size: 16px;
          line-height: 120%;
          color: #717D96;
        }
      }
      > .delete_button {
        cursor: pointer;
        margin-left: 36px;
        > p {
          font-weight: 600;
          font-size: 16px;
          line-height: 120%;
          color: #34AFF7;
        }
      }
    }
  }
}